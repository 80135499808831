<template>
  <div>
    <modal v-model:show="showModal" size="lg" headerClasses="text-xl pb-0">
      <template v-slot:header>{{ getTrans("messages.invoice") }}</template>
      <div class="row" v-if="transaction">
        <div class="col-6">
          <p class="mb-0">{{ getTrans("messages.from") }}:</p>
          <address>
            <!-- eslint-disable -->
            <strong>Divatraffic</strong><br/>
            c/o Diva Technologies AS<br/>
            Langarinden 5<br/>
            5132 Nyborg<br/>
            Norway<br/>
            VAT: 917900787 MVA
            <!-- eslint-enable -->
          </address>
        </div>

        <div class="col-6 text-right">
          <p class="mb-0">{{ getTrans("messages.invoice_number") }}</p>
          <p class="mb-0 text-green text-break">
            {{ transaction.transaction }}
          </p>
          <span>{{ getTrans("messages.to") }}:</span>
          <address>
            <p class="mb-0">
              {{ user.bank_name ? user.bank_name : user.name }}
            </p>
            <p v-if="user.bank_address1" class="mb-0">
              {{ user.bank_address1 }}
            </p>
            <p v-if="user.bank_city" class="mb-0">{{ user.bank_city }}</p>
            <p v-if="user.bank_zip" class="mb-0">{{ user.bank_zip }}</p>
            <p v-if="user.bank_country" class="mb-0">{{ user.bank_country }}</p>
            <p v-if="user.bank_vat" class="mb-0">
              {{ getTrans("messages.vat") }}: {{ user.bank_vat }}
            </p>
          </address>
          <p>
            <strong>{{ getTrans("messages.invoice_date") }}:</strong>
            {{ toDateTime(transaction.created) }}
          </p>
        </div>
        <hr class="w-100 my-3" />
        <table class="table w-auto ml-auto">
          <tbody>
            <tr>
              <td>{{ getTrans("messages.item_list") }}</td>
              <td class="text-right">{{ getTrans("messages.total_price") }}</td>
            </tr>
            <tr>
              <td>
                <div>
                  <strong
                    >{{ getTrans("messages.payment_via") }}
                    {{ paymentMethods[transaction.method_id] }}</strong
                  >
                </div>
                <small v-if="transaction.type === 1"
                  >{{ getTrans("messages.payment_for")
                  }}{{ Math.floor(transaction.amount_req) * 10 }}
                  {{ getTrans("messages.tokens").toLowerCase() }}</small
                >
                <small v-else>{{
                  getTrans("messages.payments_subscription")
                }}</small>
              </td>

              <td class="text-right">
                {{ toDollarsWithDigits(transaction.amount_req) }}
              </td>
            </tr>
            <tr class="no-border">
              <td class="text-right">{{ getTrans("messages.subtotal") }}:</td>
              <td class="text-right">
                {{ toDollarsWithDigits(transaction.amount_req) }}
              </td>
            </tr>
            <tr class="no-border">
              <td class="text-right">{{ getTrans("messages.fee") }}:</td>
              <td class="text-right">
                {{ toDollarsWithDigits(transaction.fee) }}
              </td>
            </tr>
            <tr class="no-border">
              <td class="text-right">
                <strong>{{ getTrans("messages.amount") }}:</strong>
              </td>
              <td class="text-right">
                {{
                  toDollarsWithDigits(
                    Number(transaction.fee) + Number(transaction.amount_req)
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateFormat from "@/mixins/DateFormat";
import Modal from "@/components/Modal";
import StringFormatter from "@/mixins/StringFormatter";

export default {
  name: "invoice-modal",
  mixins: [DateFormat, StringFormatter],
  components: {
    Modal,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      showModal: true,
    };
  },
  computed: {
    ...mapGetters("User", {
      user: "getUser",
    }),
    ...mapGetters("Transaction", {
      paymentMethods: "paymentMethods",
    }),
  },
  watch: {
    showModal(newVal) {
      this.$emit("update:showModal", newVal);
    },
  },
};
</script>
<style scoped>
.table td {
  padding: 0.2rem;
}
</style>
