<template>
  <div>
    <teleport to="body" v-if="showModal">
      <InvoiceModal :transaction="transaction" v-model:showModal="showModal"></InvoiceModal>
    </teleport>
    <base-button
      class=""
      size="sm"
      type="primary"
      @click="showModal = true"
    >{{ getTrans('messages.preview') }}</base-button>
    <a :href="downloadUrl" class="ml-1">
      <base-button
        class=""
        size="sm"
        type="primary"
      >{{ getTrans('messages.download') }}</base-button></a>
  </div>
</template>

<script>

import BaseButton from "@/components/BaseButton";
import DateFormat from "@/mixins/DateFormat";
import InvoiceModal from "./InvoiceModal";

export default {
  name: "invoice-links",
  mixins: [DateFormat],
  components: {
    BaseButton,
    InvoiceModal,
  },
  props: ['transaction'],
  data: function() {
    return {
      showModal: false
    };
  },
  computed: {
    downloadUrl() {
      return process.env.VUE_APP_API_URL + 'payment/'+ this.transaction.id + '/pdf';
    },
  },
};
</script>
