<template>
  <div>
    <diva-card>
      <el-tabs @tab-click="tabHandler" v-model="activeTab">
        <el-tab-pane :label="getTrans('messages.all')" name="all"></el-tab-pane>
        <el-tab-pane :label="getTrans('messages.into_account')" name="increase"></el-tab-pane>
        <el-tab-pane :label="getTrans('messages.out_account')" name="decrease"></el-tab-pane>
        <el-tab-pane :label="getTrans('messages.payments')" name="payments"></el-tab-pane>
      </el-tabs>
      <content-loader v-if="loading"></content-loader>
      <template v-else>
        <store-pagination :store="'Transaction'" @update:modelValue="loadData"></store-pagination>
        <payments-table :data="payments" v-if="activeTab == 'payments'"></payments-table>
        <balance-table :data="balance" v-else></balance-table>
      </template>
    </diva-card>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {ElTabs, ElTabPane} from "element-plus";
import BalanceTable from "./Components/BalanceTable.vue";
import PaymentsTable from "./Components/PaymentsTable";
import StorePagination from "@/components/StorePagination";

export default {
  name: "transactions",
  components: {
    [ElTabs.name]: ElTabs,
    [ElTabPane.name]: ElTabPane,
    'balance-table': BalanceTable,
    'payments-table': PaymentsTable,
    'store-pagination': StorePagination
  },
  data() {
    return {
      activeTab: 'all',
      showPagination: false
    };
  },
  computed: {
    ...mapGetters('Transaction', {
      pagination: 'pagination',
      loadingByName: 'loadingByName',
      getData: 'getDataByName',
    }),
    balance() {
      return this.getData('balance');
    },
    payments() {
      return this.getData('payments');
    },
    loading() {
      return this.loadingByName('balance') || this.loadingByName('payments');
    }
  },
  methods: {
    tabHandler() {
      this.showPagination = false;
      this.loadData(1);
    },
    async loadData(page = 1) {
      if (this.activeTab === 'payments') {
        await this.$store.dispatch("Transaction/loadPayments", {
          sorting: {id: 'desc'},
          page: page
        });
        this.showPagination = !!this.payments;
      } else {
        await this.$store.dispatch("Transaction/loadBalance", {
          filter: {type: this.activeTab},
          page: page
        });
        this.showPagination = !!this.balance;
      }
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>
