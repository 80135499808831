<template>
  <div>
    <div class="table-responsive">
      <table class="table table-flush" id="datatable-basic">
        <thead class="thead-light">
        <tr>
          <th>{{ getTrans('messages.date') }}</th>
          <th>{{ getTrans('messages.comment') }}</th>
          <th>{{ getTrans('messages.amount') }}</th>
          <th>{{ getTrans('messages.balance') }}</th>
          <th>{{ getTrans('messages.invoice') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(balance, index) in data" :key="index">
          <td>{{ toDateTime(balance.created_at) }}</td>
          <td>{{ balance.comment }}</td>
          <td :class="balance.amount > 0 ? 'text-green' : 'text-red'">{{ tokensBalance(balance.amount) }}</td>
          <td>{{ tokensBalance(balance.balance) }}</td>
          <td>
            <invoice-links :transaction="balance.transaction.data" v-if="balance.transaction_id"></invoice-links>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import DateFormat from "@/mixins/DateFormat";
import InvoiceLinks from "./InvoiceLinks";
import StringFormatter from "@/mixins/StringFormatter";

export default {
  name: "balance-table",
  mixins: [DateFormat, StringFormatter],
  components: {
    InvoiceLinks,
  },
  props: ['data'],
  computed: {

  },
  methods: {

  },
};
</script>
