<template>
  <div>
    <div class="table-responsive">
      <table class="table table-flush" id="datatable-basic">
        <thead class="thead-light">
        <tr>
          <th>{{ getTrans('messages.date') }}</th>
          <th>{{ getTrans('messages.comment') }}</th>
          <th>{{ getTrans('messages.payment_method') }}</th>
          <th>{{ getTrans('messages.type') }}</th>
          <th>{{ getTrans('messages.fee') }}</th>
          <th>{{ getTrans('messages.total') }}</th>
          <th>{{ getTrans('messages.invoice') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(payment, index) in data" :key="index">
          <td>{{ toDateTime(payment.created) }}</td>
          <td>{{ getComment(payment) }}</td>
          <td>{{ paymentMethods[payment.method_id] }}</td>
          <td>{{ getType(payment) }}</td>
          <td>{{ toDollarsWithDigits(payment.fee) }}</td>
          <td>{{ toDollarsWithDigits(getTotal(payment)) }}</td>
          <td>
            <invoice-links :transaction="payment"></invoice-links>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import DateFormat from "@/mixins/DateFormat";
import InvoiceLinks from "./InvoiceLinks";
import {mapGetters} from "vuex";
import StringFormatter from "@/mixins/StringFormatter";

export default {
  name: "payments-table",
  mixins: [DateFormat, StringFormatter],
  components: {
    InvoiceLinks,
  },
  props: ['data'],
  computed: {
    ...mapGetters('Transaction', [
      'paymentMethods'
    ]),
  },
  methods: {
    getType(payment) {
      let type = this.getTrans('messages.tokens');
      if (payment.type === 2) {
        type = this.getTrans('messages.subscription_header');
      }
      return type;
    },
    getComment(payment) {
      let comment = '';
      if (payment.transactionBalance) {
        comment = payment.transactionBalance.data.comment;
      } else if (payment.type === 2) {
        comment = this.getTrans('messages.subscription_payment');
      }

      return comment;
    },
    getTotal(payment) {
      return parseFloat(payment.amount_req) + parseFloat(payment.fee);
    },
  },
};
</script>
